.App-disclaimer {
    background: #6d757d;
    color: #fff;
}

.App-disclaimer-content {
    width: 99%;
    margin: auto;
    text-align: center;
    font-size: 12px;
    padding: 10px 0;;
}
